<div class="bg-primary">
    <div class="flex px-3 mx-auto bg-primary pt-12 lg:pt-20 pb-1">
        <app-text-input
                class="w-full"
                [floatLabel]="true"
                [label]="'Пребарај'"
                [control]="searchControl"
                [type]="'required'"></app-text-input>
        <div class="w-9 lg:w-10 button-on-hover z-[100] my-auto">
            <app-primary-button
                    [class]="'relative justify-self-end'"
                    [icon]="'/assets/icons/close.svg'"
                    [tooltip]="'CLOSE'"
                    (click)="closeSearchOverview()"></app-primary-button>
        </div>
    </div>
    @if (products.length > 0) {
        <div class="px-3 pb-1">
            <app-text
                    [value]="products.length > 1? 'вкупно резултати: ' + pageInfo.totalElements: ''"></app-text>
        </div>
        <div class="bg-white pt-10 mx-auto grid gap-8 grid-cols-2 lg:grid-cols-3 h-[80vh] overflow-y-auto px-4 pb-24 lg:pb-44">
            @for (product of products; track product) {
                <div>
                    <app-card
                            [product]="product"
                            (click)="setSelectedProduct(product)"
                            [showAddToCartButton]="false"></app-card>
                </div>
            }
        </div>
    } @else if (this.searchControl.value) {
        <app-text class="px-3 py-10 lg:py-20 lg:w-1/3 mx-auto"
                  [value]="noResultsMessage"></app-text>
    }
</div>