import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {filter, map, mergeMap} from "rxjs";
import {environment} from "./environments/environment.production";
import {setCanonicalUrl} from "./services/util/util";
import {FBQ} from "./models/fbq";

declare let fbq: FBQ;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta
    ) {
    }

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute.root),
            map(route => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            mergeMap(route => route.data)
        ).subscribe(data => {
            fbq('track', 'PageView');
            const title = data['title'] || 'Алое Вера Гел MK - Природна нега со алое вера гел и производи за здравје и убавина!';
            const description = data['metaDescription'] || 'Алое Вера Гел.мк – Специјализирани алое вера производи за природна нега на тело и лице, контрола на тежина и здрав живот. Откријте висококвалитетни алое вера гелови и производи со природни состојки за секојдневна убавина и здравје.';
            const canonicalUrl = environment.domain.frontend + data['canonicalUrl'];

            this.titleService.setTitle(title);
            this.metaService.updateTag({name: 'description', content: description});

            setCanonicalUrl(canonicalUrl);
        });
    }
}