import {Component, OnInit} from '@angular/core';
import {initTE, Modal, Sidenav} from "tw-elements";
import {CartService} from "../../modules/cart/service/cart.service";
import {OrderItem} from "../../models/order-item";
import {Router} from "@angular/router";

@Component({
    selector: 'app-cart-overview',
    templateUrl: './cart-overview.component.html',
    styleUrl: './cart-overview.component.css'
})
export class CartOverviewComponent implements OnInit {

    orderItems: OrderItem[] = [];

    orderPrice: number = 0;

    deliveryExpenses: number = 150;

    totalPrice: number = 0;

    quantity: number = 0;

    constructor(
        private readonly router: Router,
        private readonly cartService: CartService) {
        this.cartService.cartItems$.subscribe(items => this.orderItems = items);
    }

    ngOnInit(): void {
        initTE({Sidenav, Modal});
        this.updateCart();
    }

    updateCart() {
        this.orderItems = this.cartService.retrieveItemsFromLocalStorage();
        this.calculateTotal();
    }

    calculateTotal() {
        this.totalPrice = 0;
        this.orderPrice = 0;

        for (const orderItem of this.orderItems) {
            if (orderItem.product && orderItem.product.price && orderItem.quantity) {
                this.orderPrice += orderItem.product.price * orderItem.quantity;
            }
        }
        if (this.orderPrice < 2400) {
            this.totalPrice += this.deliveryExpenses;
        }

        this.totalPrice += this.orderPrice;
    }

    removeItemFromLocalStorage(index: number) {
        this.cartService.removeItemFromLocalStorage(index);
        this.updateCart();
    }

    updateOrderItemQuantity(index: number, newQuantity: number): void {
        const existingItems = this.getExistingItems();

        const orderItemToUpdate = existingItems[index];
        orderItemToUpdate.quantity = newQuantity;
        existingItems[index] = orderItemToUpdate;

        localStorage.setItem('cartItems', JSON.stringify(existingItems));
        this.updateCart()
    }

    private getExistingItems(): OrderItem[] {
        const existingItemsJson = localStorage.getItem('cartItems');
        return existingItemsJson ? JSON.parse(existingItemsJson) : [];
    }

    goToUrl(url: string) {
        const cartOverview = new Sidenav(document.getElementById("cart-overview"));
        cartOverview.hide();
        this.router.navigateByUrl(url).then(() => {
            window.scrollTo(0, 0);
        })
    }

}
