@if (blogs.length > 0) {
    <div id="latestBlogPosts" [class]="'h-fit pb-16 pt-8 lg:pt-12 px-5'">
        <div [@slideInFromBottom]="showAnimation ? 'visible' : 'hidden'">
            <app-text class="text-center text-4xl lg:text-5xl font-black"
                      [value]="'Најнови блог постови'"></app-text>
        </div>
        <div class="grid-cols-1 sm:grid md:grid-cols-3 pt-5 lg:pt-10 lg:px-24"
             [ngClass]="blogs.length === 0 ? 'h-screen' : ''">
            @for (blog of blogs; track blog) {
                @if (blog.isPublished) {
                    <div class="duration-200 transform hover:scale-105 transition-transform bg-neutral-200 border-x-2 border-b-2 border-neutral-400 mx-3 mt-6 flex flex-col rounded-lg text-surface dark:bg-surface-dark dark:text-white sm:shrink-0 sm:grow sm:basis-0">
                        <button data-te-ripple-init
                                data-te-ripple-color="light"
                                (click)="goToUrl(router, 'blogs/' + (blog?.slug ? blog?.slug + '-' + blog?.id : blog?.id))">
                            <app-image [class]="'rounded-t-lg border-4 border-secondary z-50'"
                                       [src]="blog.mainImage"></app-image>
                        </button>
                        <div class="p-6">
                            <app-text class="mb-2 text-xl font-medium leading-tight text-gray-800"
                                      [value]="blog.title"></app-text>
                            <app-text class="mb-4 text-base"
                                      [value]="blog.summary"></app-text>
                        </div>
                        <button data-te-ripple-init
                                data-te-ripple-color="light"
                                class="underline text-left px-6 text-gray-900 pb-2"
                                (click)="goToUrl(router, 'blogs/' + (blog?.slug ? blog?.slug + '-' + blog?.id : blog?.id))">
                            Прочитај повеќе
                        </button>
                        <div class="mt-auto border-t-2 border-neutral-300 px-6 py-3 text-center text-surface/75 dark:border-white/10 dark:text-neutral-300">
                            <div class="flex justify-start text-sm lg:text-base">
                                <a [href]="blog.author.link">
                                    @if (blog.author.image) {
                                        <app-image
                                                [src]="blog.author.image"
                                                class="w-8 lg:w-9 rounded-full shadow-lg cursor-pointer"
                                                [alt]="blog.author.image"
                                        />
                                    }
                                </a>
                                <p class="my-auto pl-1">
                                    Од <span class="text-gray-900 hover:underline cursor-pointer"> <a
                                        [href]="blog.author.link">{{ blog.author.name }}</a></span> |
                                    {{ formatDate(datePipe, blog.publishDate!) }}
                                </p>
                            </div>
                            @if (auth.user$ | async; as user) {
                                @if (user.email === environment.admin) {
                                    <app-secondary-button [value]="'Уреди'"
                                                          class="pt-2 flex mx-auto"
                                                          (click)="goToUrl(router, 'blogs/' + blog?.id + '/edit')"
                                    ></app-secondary-button>
                                }
                            }
                        </div>
                    </div>
                }
            }
        </div>
        <div class="mx-auto md:w-1/2 pt-10">
            <app-secondary-button
                    (click)="goToUrl(router, '/blogs')" class="flex w-2/3 md:w-80 mx-auto"
                    [value]="'Види ги сите блог постови'"></app-secondary-button>
        </div>
    </div>
}