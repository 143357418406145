export const environment = {
    production: true,
    domain: {
        frontend: 'https://aloeveragel.mk',
        backend: `https://aloeveragel.mk:8443/api/v1`,
    },
    navigation: {
        home: '/',
        aboutUs: '/about-us',
        shop: '/shop',
        blog: '/blogs',
        contact: '/contact',
        shoppingCart: '/cart',
        profile: '/profile',
        contactUs: '/contact-us',
        brandAmbassador: '/biznis-moznost'
    },
    api: {
        products: '/products',
        discounts: '/discounts',
        customers: '/customers',
        orders: '/orders',
        notifications: '/notifications',
        spotify: '/spotify',
        blogs: '/blogs'
    },
    auth0: {
        domain: 'dev-dimolg3ruc0guvxs.eu.auth0.com',
        clientId: '2UqDhEYp5trLQ4ZJK0b5RhWyngbzwXEo'
    },
    admin: "kiko.milenkovski10@gmail.com"
}
