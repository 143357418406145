<!--<div class="text-center mx-auto">-->
<!--    <div class="w-36 lg:h-28 mx-auto">-->
<!--        <app-svg-icon [url]="path"></app-svg-icon>-->
<!--    </div>-->
<!--    <div class="py-2">-->
<!--        <app-text class="text-2xl font-bold" [value]="title"></app-text>-->
<!--    </div>-->
<!--    <app-text [value]="text"></app-text>-->
<!--</div>-->

<div class="text-center pt-5">
    <div class="block h-full xl:h-72 2xl:h-64 rounded-lg bg-yellow-100 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
        <div class="flex justify-center">
            <div class="-mt-8 inline-block rounded-full bg-yellow-200 text-gray-800 p-3 shadow-md">
                <app-svg-icon [url]="path"></app-svg-icon>
            </div>
        </div>
        <div class="p-6">
            <app-text class="text-2xl font-bold" [value]="title"></app-text>
            <div class="pt-2">
                <app-text [value]="text"></app-text>
            </div>
        </div>
    </div>
</div>