@if (product | async; as product) {
    <div class="p-0 lg:p-16 pt-24 lg:pt-40">
        <div class="grid lg:grid-cols-2 relative mb-5 md:mb-0">
            <div class="lg:px-14 lg:pb-14">
                <!--breadcrumb-->
                <nav class="w-full rounded-md py-3 px-5 lg:px-0 text-sm lg:text-base">
                    <ol class="list-reset flex">
                        @for (link of links; track link) {
                            <li>
                                <app-link [link]="link"/>
                            </li>
                            <li>
                                <span class="mx-1.5 lg:mx-2 text-neutral-400">/</span>
                            </li>
                        }
                        <li class="text-neutral-400">{{ product.name }}</li>
                    </ol>
                </nav>
                <div id="order-dialog-carousel"
                     class="relative my-auto"
                     data-te-carousel-init>
                    <!--Carousel items-->
                    <div class="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
                        <!--First item-->
                        <div class="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                             data-te-carousel-item
                             data-te-carousel-active>
                            <app-image
                                    [src]="product.images[0]"
                                    class="block w-full"
                                    [alt]="product.altImages[0]"/>
                        </div>
                        @for (image of product?.images; track image; ) {
                            @if ($index !== 0) {
                                <div class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                                     data-te-carousel-item>
                                    <app-image
                                            [src]="product.images[$index]"
                                            class="block w-full"
                                            [alt]="product.altImages[$index]"/>
                                </div>
                            }
                        }
                    </div>

                    @if (product.images.length > 1) {
                        <!--Carousel controls - prev item-->
                        <button
                                class="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-black opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-black hover:no-underline hover:opacity-90 hover:outline-none focus:text-black focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                                type="button"
                                data-te-target="#order-dialog-carousel"
                                data-te-slide="prev">
    <span class="inline-block h-8 w-8">
      <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6">
        <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"/>
      </svg>
    </span>
                            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                            >Previous</span>
                        </button>
                        <!--Carousel controls - next item-->
                        <button class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-black opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-black hover:no-underline hover:opacity-90 hover:outline-none focus:text-black focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                                type="button"
                                data-te-target="#order-dialog-carousel"
                                data-te-slide="next">
    <span class="inline-block h-8 w-8">
      <svg xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 24 24"
           stroke-width="1.5"
           stroke="currentColor"
           class="h-6 w-6">
        <path stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"/>
      </svg>
    </span>
                            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                            >Next</span>
                        </button>
                    }
                </div>
            </div>
            <form [formGroup]="orderItemControl" class="pt-3 lg:pt-8 px-10 relative">
                @if (product && product.category) {
                    <app-text [class]="'text-2xl lg:text-4xl  font-medium text-left'"
                              [isTitle]="true"
                              [value]="product.name"></app-text>
                }
                <hr class="h-px my-1 bg-gray-400 border-0">
                <div class="flex text-xl lg:text-2xl text-left">
                    <app-text
                            [ngClass]="product?.discount? 'text-red-600': ''"
                            [value]="(product?.price! * (quantityControl.value ?? 0)) + ' ден.'"></app-text>
                    @if (product?.discount) {
                        <app-text class="line-through px-1.5"
                                  [value]="(product?.originalPrice! * (quantityControl.value ?? 0)) + ' ден.'"></app-text>
                    }
                </div>
                <div class="mt-5 lg:mt-10">
                    <div class="pb-5 lg:pb-10 flex justify-start">
                        <app-quantity-selector
                                [control]="quantityControl"
                                [placeholder]="'Quantity'"></app-quantity-selector>
                    </div>
                    @if (product.disclaimer) {
                        <app-text [class]="'text-sm lg:text-lg pt-3 lg:pt-5 text-left text-red-500'"
                                  [value]="product?.disclaimer"></app-text>
                    }
                    @if (product.content) {
                        <div [innerHTML]="product.content"></div>
                    } @else {
                        <app-text [class]="'text-sm lg:text-lg pt-3 lg:pt-5 text-left'"
                                  [value]="product?.description"></app-text>
                    }
                    <div class="pt-7 lg:pt-14">
                        <app-secondary-button
                                class="flex justify-center items-center"
                                value="Додади во кошничка"
                                (click)="addToCart()"></app-secondary-button>
                        <app-primary-button
                                class="pt-3 pb-5 flex justify-center items-center"
                                value="Купи сега"
                                (click)="buyNow()"></app-primary-button>
                    </div>
                </div>
            </form>
            <app-toast [message]="toastMessage"></app-toast>
        </div>
    </div>
    @if (products.length > 0) {
        <app-related-products [relatedProducts]="products"></app-related-products>
    } @else {
        <app-related-products [product]="product"></app-related-products>
    }
    <app-footer></app-footer>
}