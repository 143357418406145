import {Component, OnInit} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "@auth0/auth0-angular";
import {environment} from "../../environments/environment";
import {goToUrl} from "../../services/util/util";

@Component({
    selector: 'app-brand-ambassador',
    templateUrl: './brand-ambassador.component.html',
    styleUrl: './brand-ambassador.component.css'
})
export class BrandAmbassadorComponent implements OnInit {

    toastMessage: string = '';

    constructor(
        private titleService: Title,
        private route: ActivatedRoute,
        public auth: AuthService,
        protected readonly router: Router,
        private meta: Meta) {
    }

    ngOnInit(): void {
        this.route.data.subscribe(data => {
            if (data['title']) {
                this.titleService.setTitle(data['title']);
                this.meta.updateTag({name: 'description', content: data['metaDescription']});
            }
        });
    }

    protected readonly environment = environment;
    protected readonly goToUrl = goToUrl;
}
