import {Component, HostListener} from '@angular/core';
import {Link} from "../../models/link";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-icon-text-list',
    templateUrl: './icon-text-list.component.html',
    styleUrls: ['./icon-text-list.component.css'],
    animations: [
        trigger('slideFromLeft', [
            state('hidden', style({
                transform: 'translateX(-80%)',
                opacity: 0
            })),
            state('visible', style({
                transform: 'translateX(0)',
                opacity: 1
            })),
            transition('hidden => visible', animate('0.8s')),
        ])
    ]
})

export class IconTextListComponent {

    showAnimation: boolean = false;

    links: Link[] = [
        {
            route: "/assets/icons/quality.svg",
            title: "КВАЛИТЕТОТ НИ Е ПРИОРИТЕТ",
            text: "Нашите производи се создадени во најсовремени процеси, што овозможува долготрајна употреба и ефективност. Вашето здравје е наша основна цел."
        },
        {
            route: "/assets/icons/delivery-truck.svg",
            title: "ДОСТАВА НИЗ ЦЕЛА МАКЕДОНИЈА",
            text: "Нашите производи ги доставуваме низ цела Македонија."
        },
        {
            route: "/assets/icons/cash-on-delivery.svg",
            title: "ПЛАЌАЊЕ ПРИ ДОСТАВА",
            text: "Плаќате кога ќе ви се достави пратката на врата."
        },
        // {
        //     route: "/assets/icons/heart_on_hand.svg",
        //     title: "ХУМАНИТАРНИ ДОНАЦИИ",
        //     text: "Дел од профитот од секоја нарачка се донира во хуманитарна организација."
        // },
    ]

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const componentElement = document.getElementById('iconListText');
        if (componentElement) {
            const rect = componentElement.getBoundingClientRect();
            this.showAnimation = rect.top <= window.innerHeight && rect.bottom >= 0;
        }
    }

}
