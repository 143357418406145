<div class="relative bg-primary min-h-full lg:min-h-fit lg:h-72 py-10">
    <!--    Navigation Links-->
    <div class="grid lg:grid-cols-7 text-center text-white text-xl font-medium w-11/12 mx-auto">
        @for (link of links; track link; let i = $index) {
            <nav class="pt-5">
                <app-text [value]="link.title"/>
                @if (i !== 1) {
                    <div class="{{link.class}} text-base font-medium pt-3 lg:pt-8">
                        <app-link class="hover:underline" [link]="link"/>
                    </div>
                } @else {
                    <div class="text-white text-base font-medium pt-3 lg:pt-8 lg:grid lg:grid-cols-2">
                        @for (link of shopLinks; track link) {
                            <div [class]="'pt-1'">
                                <app-link class="hover:underline" [link]="link"/>
                            </div>
                        }
                    </div>
                }
            </nav>
        }
        <div class="pt-5">
            <app-text class="uppercase" value="СЛЕДИ Нè"/>
            <!-- Social Media Icons -->
            <div class="flex pt-3 lg:pt-8 justify-center relative">
                @for (iconLink of iconLinks; track iconLink) {
                    <app-primary-button class="icon-on-hover fill-white"
                                        [icon]="iconLink.icon"
                                        (click)="openExternalLink(iconLink.link)"></app-primary-button>
                }
            </div>
        </div>
    </div>
    <app-text class="absolute font-extralight text-center text-gray-100 bottom-2 left-0 right-0"
              value="Copyright 2024 @aloeveragel.mk"/>
</div>
