@if (relatedProducts.length > 0) {
    <app-text class="lg:px-20 px-5 font-medium lg:text-4xl text-2xl text-left"
              [value]="'Производи што може да ти се допаднат:'"></app-text>
    <div class="bg-white pt-5 mx-auto grid gap-8 grid-cols-2 lg:grid-cols-4 overflow-y-auto px-5 lg:px-20 pb-20 lg:pb-32">
        @for (product of relatedProducts; track product) {
            <div class="transform hover:scale-110 transition-transform shadow-lg py-3 px-1 lg:py-5 rounded-lg border-2 cursor-pointer">
                <app-card
                        [product]="product"
                        (click)="setSelectedProduct(product)"
                        [showAddToCartButton]="false"></app-card>
            </div>
        }
    </div>
}