@if (playlist) {
    <div class="flex flex-col items-center lg:items-end self-end">
        <div class="pt-10 lg:pt-1 flex flex-col items-center pr-0 lg:pr-2">
            <div class="rounded-lg overflow-hidden border-2 border-black bg-primary shadow-lg p-1 z-60">
                <youtube-player
                    #youtubePlayer
                    height="100"
                    width="200"
                    [videoId]="currentTrackId"
                    (stateChange)="onPlayerStateChange($event)"
                    suggestedQuality="highres"
                    placeholderImageQuality="high">
                </youtube-player>
            </div>
            <div class="flex space-x-8 pt-1">
                <app-primary-button
                        class="w-9 lg:w-10 button-on-hover shadow-md"
                        [class]="'relative justify-self-center'"
                        [icon]="'/assets/icons/previous.svg'"
                        (click)="previousTrack()"></app-primary-button>
                <app-primary-button
                        class="w-9 lg:w-10 button-on-hover shadow-md"
                        [class]="'relative justify-self-center'"
                        [icon]="'/assets/icons/next.svg'"
                        (click)="nextTrack()"></app-primary-button>

            </div>
        </div>
    </div>
}