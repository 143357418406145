import {Component, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";
import {animate, animateChild, group, query, stagger, style, transition, trigger} from "@angular/animations";
import {IconLink} from "../../models/iconLink";
import {ActivatedRoute, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {goToUrl} from "../../services/util/util";


@Component({
    selector: 'app-for-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.css'],
    animations: [
        trigger('slideWrapper', [
            transition(':enter', group([
                query('@slideUp',
                    stagger('0.3s', [animateChild()])
                )]))
        ]),
        trigger('slideUp', [
            transition(':enter', [
                style({transform: 'translateY(50%)', opacity: 0}),
                animate('0.5s')
            ])
        ])
    ]
})
export class AboutUsComponent implements OnInit {

    protected readonly environment = environment;

    iconLinks: IconLink[] = [
        {
            icon: '/assets/icons/social-media/instagram_logo.svg',
            link: 'https://www.instagram.com/aloeveragel.mk/'
        },
        {
            icon: '/assets/icons/social-media/facebook_logo.svg',
            link: 'https://www.facebook.com/profile.php?id=61568994295824'
        },
        {
            icon: '/assets/icons/social-media/tiktok_logo.svg',
            link: 'https://www.tiktok.com/@aloeveragel.mk'
        }
    ]

    constructor(private titleService: Title,
                private route: ActivatedRoute,
                protected readonly router: Router,
                private meta: Meta) {
    }

    ngOnInit(): void {
        this.route.data.subscribe(data => {
            if (data['title']) {
                this.titleService.setTitle(data['title']);
                this.meta.updateTag({name: 'description', content: data['metaDescription']});
            }
        });
    }

    openExternalLink(url: string): void {
        window.open(url, '_blank');
    }

    protected readonly goToUrl = goToUrl;
}
