import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Product} from "../../models/product";
import {UntypedFormControl} from "@angular/forms";
import {OrderItem} from "../../models/order-item";
import {initTE, Modal, Ripple} from "tw-elements";
import {Router} from "@angular/router";
import {goToUrl} from "../../services/util/util";
import {environment} from "../../environments/environment";
import {AuthService} from "@auth0/auth0-angular";


@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit, OnChanges {
    @Input()
    quantityControl: UntypedFormControl = new UntypedFormControl();

    @Input()
    quantity: number = 0;

    @Input()
    product?: Product;

    @Input()
    orderItem?: OrderItem;

    @Input()
    orderItemPrice: number = 0;

    @Input()
    layout: 'vertical' | 'horizontal' = 'vertical'

    @Input()
    showAddToCartButton: boolean = true;

    @Output() quantityChange = new EventEmitter<number>();

    @Output() removeItemEvent = new EventEmitter<void>();

    emitRemoveItemEvent() {
        this.removeItemEvent.emit();
    }

    onQuantityChange(quantity: number) {
        this.quantityChange.emit(quantity);
    }

    constructor(
        protected readonly router: Router,
        public auth: AuthService
    ) {
    }

    ngOnInit(): void {
        initTE({Modal, Ripple});
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['orderItem'] && changes['orderItem'].currentValue) {
            this.quantityControl.setValue(this.orderItem?.quantity);
        }
    }

    protected readonly goToUrl = goToUrl;
    protected readonly environment = environment;
}