import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.css']
})
export class TextComponent {
    @Input()
    value: string | undefined;

    @Input()
    class: string | undefined;

    @Input()
    isTitle: boolean = false;
}
