import {Component} from '@angular/core';
import {ProductCategory} from "../../models/productCategory";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {environment} from "../../environments/environment";

@Component({
    selector: 'app-product-category-list',
    templateUrl: './product-category-list.component.html',
    styleUrls: ['./product-category-list.component.css'],
    animations: [
        trigger('slideInFromBottom', [
            state('hidden', style({
                transform: 'translateY(200%)',
                opacity: 0
            })),
            state('visible', style({
                transform: 'translateY(0)',
                opacity: 1
            })),
            transition('hidden <=> visible', animate('0.7s')),
        ])]
})
export class ProductCategoryListComponent {

    productCategories: ProductCategory[] = [
        {
            image: 'https://cdn.foreverliving.com/content/products/images/aloe_vera_gel__pd_category_256_X_256_1701874285274.jpg',
            link: {
                text: 'АЛОЕ ВЕРА ПИЈАЛОЦИ',
                route: environment.navigation.shop + '/aloe-vera-pijaloci'
            }
        },
        {
            image: 'https://cdn.foreverliving.com/content/products/images/FOREVER_BEE_PROPOLIS_pd_category_256_X_256_1669037162396.jpg',
            link: {
                text: 'ПЧЕЛНИ ПРОИЗВОДИ',
                route: environment.navigation.shop + '/pcelni-proizvodi'
            }
        },
        {
            image: 'https://cdn.foreverliving.com/content/products/images/dx4_body_balancing_system_pd_category_256_X_256_1669808080165.jpg',
            link: {
                text: 'ДОДАТОЦИ ВО ИСХРАНА',
                route: environment.navigation.shop + '/dodatoci-vo-ishrana'
            }
        },
        {
            image: 'https://lwf.mk/public/wp-content/uploads/2023/02/aloe-body-lotion.jpg',
            link: {
                text: 'ЛИЧНА НЕГА',
                route: environment.navigation.shop + '/licna-nega'
            }
        },
        {
            image: 'https://aloevera.mk/wp-content/uploads/2023/12/R3-Factor-Skin-Defence-Creme-s-1024x1024.jpg',
            link: {
                text: 'НЕГА ЗА ЛИЦЕ',
                route: environment.navigation.shop + '/nega-za-lice'
            }
        },
        {
            image: 'https://cdn.foreverliving.com/portals/titan-ecommerce/template1/assets/images/no_image_256x256.png',
            link: {
                text: 'ЕТЕРИЧНИ МАСЛА',
                route: environment.navigation.shop + '/etericni-masla'
            }
        },
        {
            image: 'https://cdn.foreverliving.com/content/products/images/ALOE_PROPOLIS_CREME_pd_category_256_X_256_1669126070066.jpg',
            link: {
                text: 'НЕГА ЗА ТЕЛО',
                route: environment.navigation.shop + '/nega-za-telo'
            }
        },
        {
            image: 'https://aloevera.mk/wp-content/uploads/2023/12/471_Forever_Lite_Ultra_Chocolate_l.png',
            link: {
                text: 'КОНТРОЛА НА ТЕЛЕСНА ТЕЖИНА',
                route: environment.navigation.shop + '/kontrola-na-telesna-tezina'
            }
        }
    ]

}
