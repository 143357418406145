import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Product} from "../../../../models/product";
import {OrderItem} from "../../../../models/order-item";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Carousel, initTE, Modal, Sidenav} from "tw-elements";
import {Router} from "@angular/router";
import {CartService} from "../../../cart/service/cart.service";
import {FBQ} from "../../../../models/fbq";

declare let fbq: FBQ;

@Component({
    selector: 'app-order-dialog',
    templateUrl: './order-dialog.component.html',
    styleUrls: ['./order-dialog.component.css']
})

export class OrderDialogComponent implements OnInit, OnDestroy {

    orderItemControl: UntypedFormGroup = new UntypedFormGroup({
        quantity: new UntypedFormControl('', [Validators.required]),
    });

    @Input()
    selectedProduct!: Product | null;

    toastMessage: string = '';

    constructor(private readonly router: Router,
                private cartService: CartService) {
    }

    ngOnInit(): void {
        initTE({Modal, Carousel});
        window.addEventListener('popstate', this.handlePopState);
    }

    ngOnDestroy(): void {
        window.removeEventListener('popstate', this.handlePopState);
    }

    handlePopState = () => {
        const modalElement = document.getElementById("orderDialog");
        if (modalElement) {
            const modalInstance = Modal.getInstance(modalElement);
            if (modalInstance) {
                modalInstance.hide();
            }
        }
    };

    get quantityControl(): UntypedFormControl {
        return this.orderItemControl.get('quantity') as UntypedFormControl;
    }

    addToCart() {
        this.processOrderItem();
        fbq('track', 'AddToCart', {
            content_name: this.selectedProduct?.name,
            contents: {
                id: this.selectedProduct?.id,
                quantity: this.quantityControl.value,
            },
            content_category: this.selectedProduct?.category,
            value: this.selectedProduct?.price,
            currency: 'MKD',
            content_type: 'product'
        });
        const cartOverview = new Sidenav(document.getElementById("cart-overview"));
        cartOverview.show();
    }

    buyNow() {
        this.processOrderItem();
        this.router.navigateByUrl('/checkout');
    }

    private processOrderItem(): OrderItem {
        const orderItem = this.createOrderItem();
        this.cartService.updateCartItems([...this.cartService.retrieveItemsFromLocalStorage(), orderItem]);
        this.updateLocalStorage(orderItem);
        return orderItem;
    }

    private createOrderItem(): OrderItem {
        return {
            product: this.selectedProduct,
            quantity: this.quantityControl.value
        };
    }

    private updateLocalStorage(orderItem: OrderItem): void {
        const existingItems = this.getExistingItems();
        existingItems.push(orderItem);
        localStorage.setItem('cartItems', JSON.stringify(existingItems));
    }

    private getExistingItems(): OrderItem[] {
        const existingItemsJson = localStorage.getItem('cartItems');
        return existingItemsJson ? JSON.parse(existingItemsJson) : [];
    }

}